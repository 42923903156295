import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers/params";
import getOrderByQuery from "/app/src/helpers/table";
import { ColumnSort } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type PaginatedService<TItem, TParams = URLSearchParams> = {
  getAll: (params?: TParams) => Promise<{
    [key: string]: TItem[];
  }>;
  getCount: (params?: TParams) => Promise<{
    count: number;
  }>;
};

/**
 * A hook to manage and fetch paginated data using a given service.
 *
 * @param {Object} params - The parameters for fetching paginated data.
 * @param {string[]} params.queryKey - Array of strings to uniquely identify the query.
 * @param {string} [params.searchString=""] - Optional search string for filtering results.
 * @param {number} [params.page] - The current page number for pagination.
 * @param {number} [params.pageSize] - The number of items per page.
 * @param {ColumnSort[]} [params.sort] - Array of sorting options.
 * @param {PaginatedService<T, URLSearchParams>} params.service - The service to fetch data from.
 * @param {Record<string, unknown>} [params.options={}] - Additional options for the query.
 * @param {number | false} [params.refetchInterval=false] - Interval for refetching data automatically.
 *
 * @returns {Object} An object containing paginated data, count, fetching status, a refetch function, and query keys.
 */
export default function usePaginatedData<T>({
  queryKey,
  searchString = "",
  sort,
  service,
  options = {},
  refetchInterval = false,
  queryParams = true,
}: {
  queryKey: string[];
  searchString?: string;
  sort?: ColumnSort[];
  service: PaginatedService<T, URLSearchParams>;
  options?: Record<string, unknown>;
  refetchInterval?: number | false;
  queryParams?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Initialize page as zero-based from URL
  const [page, setPage] = useState(
    parseInt(searchParams.get("page") || "1") - 1, // Convert one-based URL to zero-based
  );
  const [pageSize, setPageSize] = useState(
    parseInt(searchParams.get("pageSize") || "25"),
  );
  const {
    data,
    isFetching,
    refetch: refetchData,
  } = useQuery({
    queryKey: [...queryKey, searchString, page, pageSize, sort, options],
    queryFn: () =>
      service.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
          ...options,
        }),
      ),
    initialData: { [queryKey[0]]: [] },
    select: (response: { [key: string]: T[] }) => response[queryKey[0]],
    refetchInterval,
  });

  const { data: countData, refetch: refetchCount } = useQuery({
    queryKey: [...queryKey, "count", searchString, options],
    queryFn: () =>
      service.getCount(
        buildParams({
          search: searchString,
          ...options,
        }),
      ),
    initialData: { count: 0 },
    select: (response: { count: number }) => response.count,
    refetchInterval,
  });

  // If URL page size differs from current page size, update the URL
  useEffect(() => {
    if (queryParams) {
      const urlPageSize = searchParams.get("pageSize");
      if (urlPageSize && parseInt(urlPageSize) !== pageSize) {
        setSearchParams({
          page: (page + 1).toString(),
          pageSize: pageSize.toString(),
        });
      }
    }
  }, [searchParams, pageSize, page, setSearchParams, queryParams]);

  const totalPages = Math.ceil(countData / pageSize);
  // If countData is 0, set totalPages to 1 to avoid division by zero
  const lastValidPage = Math.max(totalPages - 1, 0);

  // Validate page and redirect if invalid
  useEffect(() => {
    if (queryParams && !isFetching) {
      if (isNaN(page) || isNaN(pageSize) || page < 0 || pageSize < 1) {
        navigate("/notFound");
      }
    }
  }, [page, pageSize, queryParams, navigate, isFetching]);

  // Redirect to last valid page if currentPage exceeds total pages
  useEffect(() => {
    if (queryParams) {
      if (!isFetching && countData > 0 && page > lastValidPage) {
        const newPage = lastValidPage;
        setSearchParams({
          page: (newPage + 1).toString(),
          pageSize: pageSize.toString(),
        });
        setPage(newPage);
      }
    }
  }, [
    isFetching,
    countData,
    page,
    lastValidPage,
    pageSize,
    setSearchParams,
    queryParams,
  ]);

  const settingPage = useCallback(
    (newPage: number) => {
      if (queryParams) {
        const validPage = Math.min(Math.max(newPage, 0), lastValidPage);
        setSearchParams({
          page: (validPage + 1).toString(), // One-based for URL
          pageSize: pageSize.toString(),
        });
        setPage(validPage);
      } else {
        setPage(newPage);
      }
    },
    [queryParams, lastValidPage, setSearchParams, pageSize],
  );

  const settingPageSize = useCallback(
    (newPageSize: number) => {
      const newTotalPages = Math.ceil(countData / newPageSize) || 1;
      const newLastValidPage = Math.max(newTotalPages - 1, 0);
      const newPage = Math.min(page, newLastValidPage);
      if (queryParams) {
        setSearchParams({
          page: (newPage + 1).toString(), // One-based for URL
          pageSize: newPageSize.toString(),
        });
      }
      setPageSize(newPageSize);
      setPage(newPage);
    },
    [countData, page, queryParams, setPageSize, setPage, setSearchParams],
  );

  const refetch = useCallback(() => {
    refetchData();
    refetchCount();
  }, [refetchData, refetchCount]);

  const getQueryKeys = useCallback(() => {
    return {
      dataQueryKey: [...queryKey, searchString, page, pageSize, sort, options],
      countQueryKey: [...queryKey, "count", searchString, options],
    };
  }, [queryKey, searchString, page, pageSize, sort, options]);

  return {
    data,
    count: countData,
    isFetching,
    refetch,
    getQueryKeys,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  };
}
