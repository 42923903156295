import React, { useEffect, useState, useMemo } from "react";

import { logService } from "/app/src/services";
import Status from "./status";
import { useTranslation } from "react-i18next";
import { Log as LogType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
import { ArrowCircleRight } from "/app/src/components/icons/icons";
import { useNavigate } from "react-router-dom";
/**
 * Shows the logs for a workflow run
 * @param param object containing workflowHistoryId
 */
export default function ActionLogs({
  workflowHistoryId,
}: {
  workflowHistoryId: number | undefined;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<LogType>();
  const columns = useMemo(() => {
    /**
     * Handles the click event for navigating to a specific report's export page.
     * @param {number} id - The unique identifier for the report.
     * @returns {Function} A function that navigates to the report's export page.
     */
    const handleClick = (id: number) => {
      return () => navigate(`/reports/${id}/export`);
    };

    /**
     * Parses a JSON string and returns the corresponding object.
     *
     * @param {string} description - The JSON string to parse.
     * @returns {any} The parsed object if the JSON string is valid, otherwise `null`.
     */
    const parseDescription = (description: string) => {
      try {
        return JSON.parse(description);
      } catch {
        return null;
      }
    };

    const baseColumns = [
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => <Status value={info.getValue()} />,
        header: t("translation:status"),
      }),
      columnHelper.accessor("appName", {
        id: "appName",
        cell: (info) => info.getValue(),
        header: t("translation:action"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => {
          const description = info.getValue();
          const parsedDescription = parseDescription(description);
          return parsedDescription?.message || description;
        },
        header: t("translation:description"),
      }),
      columnHelper.accessor("error", {
        id: "error",
        cell: (info) => info.getValue(),
        header: t("translation:error"),
      }),
      columnHelper.accessor("description", {
        id: "reportId",
        cell: (info) => {
          const description = info.getValue();
          const parsedDescription = parseDescription(description);
          if (parsedDescription?.reportId) {
            return (
              <button
                onClick={handleClick(parsedDescription.reportId)}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                <ArrowCircleRight color="green" />
              </button>
            );
          }
          return "";
        },
        header: "",
        meta: {
          sortable: false,
        },
      }),
    ];
    return baseColumns;
  }, [columnHelper, navigate, t]);

  const [sort, setSort] = useSortUpgrade([]);
  const notFoundText = t("translation:logs_not_found_workflow_history");
  const notSelectedText = t("translation:workflow_record_not_selected");
  const [emptyText, setEmptyText] = useState(notSelectedText);

  useEffect(() => {
    if (workflowHistoryId) {
      setEmptyText(notFoundText);
    }
  }, [workflowHistoryId, notFoundText]);

  const {
    data: logs,
    count: logsCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<LogType>({
    queryKey: ["logs"],
    sort,
    service: logService,
    options: { workflowHistoryId },
  });

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={logs}
        tableColumns={columns}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection={false}
        emptyText={emptyText}
      />
    </div>
  );
}
