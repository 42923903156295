import React, { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { workflowService } from "/app/src/services";
import NewWorkflowForm from "./newWorkflowForm";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Filters from "./filters";
import Controls from "./controls";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Workflow as WorkflowType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import StatusIcon from "/app/src/components/generic/components/statusIcon";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function WorkflowList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const [activeNewWorkflow, setActiveNewWorkflow] = useState(false);

  /**
   * Toggles the new workflow form
   */
  const toggleNewWorkflow = useCallback(() => {
    setActiveNewWorkflow((prev) => !prev);
  }, []);

  const columnHelper = createColumnHelper<WorkflowType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor(
        (row) => {
          return row?.lastTriggered ? row.lastTriggered : "";
        },
        {
          id: "lastTriggered",
          cell: (info) => (
            <DateTime
              date={info.getValue() !== "" ? info.getValue() : null}
              format={"MMMM Do YYYY, h:mm:ss a"}
            />
          ),
          header: t("translation:last_triggered"),
        },
      ),
      columnHelper.accessor("status", {
        id: "status",
        cell: (info) => {
          const value = info.getValue();
          return <StatusIcon status={value} />;
        },
        header: t("translation:running"),
      }),
    ],
    [columnHelper, t],
  );

  const goToWorkflow = useCallback(
    (id: number | undefined) => {
      navigate(`/workflows/${id}`);
    },
    [navigate],
  );

  const {
    data: workflows,
    count: workflowsCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<WorkflowType>({
    queryKey: ["workflows"],
    searchString,
    sort,
    service: workflowService,
  });

  return (
    <div className="workflows">
      <Helmet>
        <title>{t("translation:workflows")} - ItemPath</title>
      </Helmet>

      <Controls
        toggleNewWorkflow={toggleNewWorkflow}
        activeNewWorkflow={activeNewWorkflow}
      />
      {activeNewWorkflow ? <NewWorkflowForm /> : ""}
      <Row gutter={20}>
        <Col span={6}>
          <Filters setSearchString={setSearchString} />
        </Col>
        <Col span={18}>
          <Table
            loading={isFetching}
            rows={workflows}
            tableColumns={columns}
            length={workflowsCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage: settingPage,
              setPageSize: settingPageSize,
            }}
            enableRowSelection
            rowClicked={goToWorkflow}
            emptyText={t("translation:no_workflows")}
          />
        </Col>
      </Row>
    </div>
  );
}
