import { mappingService } from "/app/src/services";

import { Integration, Mapping } from "/app/src/models";
import { buildParams } from "/app/src/helpers";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

import { SortableTree } from "../tree/SortableTree";
import { FlattenedItem, TreeItem, TreeItems } from "../tree/types";
import { useCallback, useMemo } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";

/**
 * Component to display the mappings for a single Integration - Data Pull or Data Push
 */
export default function EditMappings({
  integration,
  mappingsType = "mappings",
}: {
  integration: Integration;
  mappingsType?: "mappings" | "confirmationMappings";
}) {
  const queryClient = useQueryClient();
  const mappingType = mappingsType === "mappings" ? "Mapping" : "Confirmation";
  //Get mappings
  const { data: mappings } = useQuery({
    queryKey: [mappingsType, integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
          orderby: "position",
          mappingType,
        }),
      );
    },
    enabled: Boolean(integration.id),
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
  });

  const mapToTreeItems = useMemo((): TreeItems => {
    const map: { [key: number]: TreeItem } = {};
    const treeItems: TreeItems = [];

    // Create a map of id to TreeItem
    mappings.forEach((mapping) => {
      if (mapping.id !== null) {
        const updatedMapping = {
          ...mapping,
          connectionType: integration.connectionType,
        };
        map[mapping.id] = {
          id: String(mapping.id),
          children: [],
          mapping: updatedMapping,
        };
      }
    });

    // Build the tree structure
    mappings.forEach((mapping) => {
      const currentItem = map[mapping.id];
      if (mapping.parentMappingId !== null && mapping.parentMappingId in map) {
        map[mapping.parentMappingId].children.push(currentItem);
      } else if (currentItem) {
        treeItems.push(currentItem);
      }
    });

    return treeItems;
  }, [integration.connectionType, mappings]);

  /**
   * Converts an array of FlattenedItem objects to an array of Mapping objects.
   *
   * @param items - An array of FlattenedItem objects.
   * @returns An array of Mapping objects extracted from the FlattenedItem objects.
   */
  const flatItemsToMappings = (items: FlattenedItem[]): Mapping[] => {
    return items.map((item) => item.mapping as Mapping);
  };

  /**
   * Handles the drop event for mapping items, updating their order and parent-child relationships.
   *
   * @param {FlattenedItem[]} items - The array of flattened items after the drop event.
   *
   * The function performs the following steps:
   * 1. Reorders the mappings based on the dropped items.
   * 2. Creates a map for easy lookup of original items by their unique identifiers.
   * 3. Checks for changes in parent-child relationships and updates the mappings accordingly.
   * 4. Updates the query client with the new mappings.
   *
   * @returns {void}
   */
  const handleMappingDrop = useCallback(
    (items: FlattenedItem[]) => {
      //Converts flat list of items into Mappings
      const reorderedMappings = flatItemsToMappings(items);

      // Create a map for easy lookup of original items by id
      const originalMap = new Map(
        mappings.map((item) => [item.id as UniqueIdentifier, item]),
      );
      const updatedMappings = [];

      const calculatePositions = () => {
        // positionMap stores the new position (as a string) for each mapping ID
        const positionMap = new Map<number, string>();

        // Creates a map to group items by their parent ID
        const parentGroups = new Map<
          UniqueIdentifier | null,
          FlattenedItem[]
        >();

        //Loop through items and group them by parent ID
        items.forEach((item) => {
          const parentId = item.parentId || null;
          if (!parentGroups.has(parentId)) {
            parentGroups.set(parentId, []);
          }
          parentGroups.get(parentId).push(item);
        });

        // Loop through parent groups and assign positions, convert the index to string formatting, store new position in positionMap
        parentGroups.forEach((groupItems) => {
          groupItems.forEach((item, index) => {
            const itemId = parseInt(item.id as string);
            positionMap.set(itemId, String(index));
          });
        });

        return positionMap;
      };

      const positionMap = calculatePositions();

      items.forEach((newItem) => {
        const newItemId = parseInt(newItem.id as string);
        const newItemParentId = newItem.parentId
          ? parseInt(newItem.parentId as string)
          : null;
        const parentItem = originalMap.get(newItemParentId);
        const originalItem = originalMap.get(newItemId);
        const newPosition = positionMap.get(newItemId) ?? "0";

        if (originalItem) {
          // Check if parentId or position has changed
          if (
            newItemParentId !== originalItem.parentMappingId ||
            newPosition !== originalItem.position
          ) {
            updateMapping({
              id: newItemId,
              parentMappingId: newItemParentId,
              position: newPosition,
            });
            updatedMappings.push({
              id: newItemId,
              parentMappingId: newItemParentId,
              parentType: parentItem?.type,
              position: newPosition,
            });
          }
        } else {
          console.warn(`Item ${newItem.id} not found in original items.`);
        }
      });

      queryClient.setQueryData([mappingsType, integration.id], () => {
        const newMappings = reorderedMappings.map((mapping) => {
          const updatedMapping = updatedMappings.find(
            (updatedMapping) => updatedMapping.id === mapping.id,
          );
          if (updatedMapping) {
            return {
              ...mapping,
              parentMappingId: updatedMapping.parentMappingId,
              parentType: updatedMapping.parentType,
              position: updatedMapping.position,
            };
          }
          return {
            ...mapping,
            position: positionMap.get(mapping.id) ?? mapping.position ?? "0",
          };
        });
        return {
          mappings: newMappings,
        };
      });
    },
    [integration.id, mappings, mappingsType, queryClient, updateMapping],
  );
  return (
    <SortableTree
      collapsible
      defaultItems={mapToTreeItems}
      onSuccessfulDrop={handleMappingDrop}
    />
  );
}
