import { useCallback } from "react";
import HeroPagination from "/app/src/components/HeroUi/Pagination";
import HeroSelect from "/app/src/components/HeroUi/Select";

export default function Paginate({
  length,
  pageSize,
  setPageSize,
  currentPage,
  setPage,
}) {
  //divide length by pageSize to get the total number of pages
  const totalPages = Math.ceil(length / pageSize);

  // Handler to change the page
  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage - 1); // Convert newPage from one-based to zero-based index
    },
    [setPage],
  );

  // Handler to change the page size
  const handlePageSizeChange = useCallback(
    (event) => {
      setPageSize(parseInt(event.target.value));
      //Adjusts the current page to display the same items
      setPage(
        Math.floor((currentPage * pageSize) / parseInt(event.target.value)),
      );
    },
    [currentPage, pageSize, setPage, setPageSize],
  );

  // Calculate the range of items displayed on the current page
  const calculateRange = () => {
    if (length === 0) {
      return "0-0 of 0 items";
    }
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, length);
    return `${startIndex}-${endIndex} of ${length} items`;
  };

  const itemsPerPage = [10, 20, 25, 50, 100];
  if (!itemsPerPage.includes(pageSize)) {
    itemsPerPage.push(pageSize);
  }

  // Sort the items in ascending order
  itemsPerPage.sort((a, b) => a - b);

  return (
    <div className="grid grid-cols-1 pt-2">
      {/* Display the range of items */}
      <div className="col-span-1 flex justify-center items-center">
        <h3 className="pr-4 mt-1">{calculateRange()}</h3>

        {/* Only render the pagination component if the length is non-negative*/}
        {totalPages > 0 && (
          <>
            {/* Render the HeroPagination component */}
            <HeroPagination
              total={totalPages}
              page={currentPage + 1} // Convert currentPage from zero-based to one-based index
              onChange={handlePageChange}
              initialPage={currentPage + 1} // Convert currentPage from zero-based to one-based index
              className="mt-1"
            />

            {/* Page size input using NextUI Select component */}
            <HeroSelect
              items={itemsPerPage.map((item) => ({
                key: item.toString(),
                value: item.toString(),
                label: `${item} / page`,
              }))}
              size="md"
              value={pageSize.toString()} // Convert pageSize to string for compatibility with Select
              onChange={handlePageSizeChange}
              defaultSelectedKeys={[pageSize.toString()]}
              className="w-40 pl-4"
              ariaLabel="Items per page"
              disallowEmptySelection
            />
          </>
        )}
      </div>
    </div>
  );
}
