import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { orderService } from "/app/src/services";

import Search from "../search";
import { useTranslation } from "react-i18next";
import { Order as OrderType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
import Box from "/app/src/components/generic/components/box";
import { Col, Row, Select } from "antd";

const { Option } = Select;

export default function Orders() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("all");
  const priorities = [
    { name: "Low (1)", value: 1 },
    { name: "Medium (2)", value: 2 },
    { name: "High (3)", value: 3 },
    { name: "Hot (4)", value: 4 },
  ];
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<OrderType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("priority", {
        id: "priority",
        cell: (info) => info.getValue(),
        header: () => t("translation:priority"),
      }),
      columnHelper.accessor("Info1", {
        id: "Info1",
        cell: (info) => info.getValue(),
        header: () => t("translation:info1"),
      }),
    ],
    [columnHelper, t],
  );

  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToOrder = useCallback(
    (orderId: number) => {
      navigate(`/explorer/orders/${orderId}`);
    },
    [navigate],
  );

  const priorityFilterChange = useCallback(
    (value) => {
      setFilter(value);
    },
    [setFilter],
  );

  const {
    data: orders,
    count: ordersCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<OrderType>({
    queryKey: ["orders"],
    searchString,
    sort,
    service: orderService,
    options: {
      state: "queued",
      priority: filter,
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:orders")} - ItemPath</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={12}>
            <Search setSearchString={setSearchString} />
          </Col>
          <Col span={12}>
            <Select
              onChange={priorityFilterChange}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_priority")}
            >
              <Option value="all">{t("translation:all")}</Option>

              {priorities.map((priority) => (
                <Option value={priority.value} key={priority.value}>
                  {priority.name}
                </Option>
              ))}
            </Select>
            {}
          </Col>
        </Row>
      </Box>
      <Table
        loading={isFetching}
        rows={orders}
        tableColumns={columns}
        length={ordersCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToOrder}
        emptyText={t("translation:no_orders")}
      />
    </div>
  );
}
