import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton } from "formik-antd";
import { Connection, Integration } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { integrationService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import SelectWithSkeleton from "/app/src/components/generic/components/selectWithSkeleton";

interface IntegrationFormValues {
  connectionId: number | undefined;
}
/**
 * Component to display the connection select for a single Data Pull/Push Integration
 */
export default function ConnectionForm({
  integration,
  connections,
  isFetchingConnections,
}: {
  integration: Integration;
  connections: Connection[];
  isFetchingConnections: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  const renderOptions = useCallback(() => {
    return connections.map((c) => (
      <Select.Option key={c.id} value={c.id}>
        {c.name}
      </Select.Option>
    ));
  }, [connections]);

  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={20}>
            <Form.Item name="connectionId" label={t("translation:connection")}>
              <SelectWithSkeleton
                loading={isFetchingConnections}
                placeholder={t("translation:select_connection")}
                name="connectionId"
                renderOptions={renderOptions}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [isFetchingConnections, renderOptions, t],
  );
  const handleSubmit = useCallback(
    async (
      values: IntegrationFormValues,
      actions: FormikHelpers<IntegrationFormValues>,
    ) => {
      if (integration?.id) {
        await updateIntegration({
          id: integration.id,
          ...values,
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [integration.id, updateIntegration],
  );
  return (
    <Formik
      component={updateIntegrationForm}
      initialValues={{ connectionId: integration.connectionId }}
      enableReinitialize
      onSubmit={handleSubmit}
    />
  );
}
