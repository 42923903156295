import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { kitService } from "/app/src/services";

import Search from "../search";

import { useTranslation } from "react-i18next";
import { Kit as KitType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
export default function Kits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnUpgrade = createColumnHelper<KitType>();
  const columns = useMemo(
    () => [
      columnUpgrade.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
    ],
    [t, columnUpgrade],
  );

  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToKit = useCallback(
    (kitId: number) => {
      navigate(`/explorer/kits/${kitId}`);
    },
    [navigate],
  );

  const {
    data: kits,
    count: kitsCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<KitType>({
    queryKey: ["kits"],
    searchString,
    sort,
    service: kitService,
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:kits")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={kits}
        tableColumns={columns}
        length={kitsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToKit}
        emptyText={t("translation:no_kits_found")}
      />
    </div>
  );
}
