import { Skeleton } from "antd";
import { Select } from "formik-antd";

interface SelectWithSkeletonProps {
  loading: boolean;
  placeholder: string;
  name: string;
  size?: "large" | "small" | "middle";
  renderOptions: () => React.ReactNode;
  showSearch?: boolean;
  optionFilterProp?: string;
}

const SelectWithSkeleton: React.FC<SelectWithSkeletonProps> = ({
  loading,
  placeholder,
  name,
  size = "large",
  renderOptions,
  showSearch = false,
  optionFilterProp,
}) => {
  // middle is not a valid size for Skeleton.Input - use default instead
  const skeletonSize = size === "middle" ? "default" : size;

  if (loading) {
    return <Skeleton.Input className="!w-full" active size={skeletonSize} />;
  }

  return (
    <Select
      name={name}
      size={size}
      placeholder={placeholder}
      showSearch={showSearch}
      optionFilterProp={optionFilterProp}
    >
      {renderOptions()}
    </Select>
  );
};

export default SelectWithSkeleton;
