import React, { useCallback, useMemo } from "react";
import { workflowHistoryService } from "/app/src/services";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useTranslation } from "react-i18next";
import { WorkflowHistory } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
export default function WorkflowHistories({
  workflowId,
  setWorkflowHistoryId,
}: {
  workflowId: number | undefined;
  setWorkflowHistoryId: (id: number) => void;
}) {
  const { t } = useTranslation();
  const [sort, setSort] = useSortUpgrade([]);

  const columnHelper = createColumnHelper<WorkflowHistory>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => (
          <DateTime date={info.getValue()} format={"MMMM Do YYYY, h:mm:ss a"} />
        ),
        header: t("translation:workflow_run_time"),
      }),
    ],
    [columnHelper, t],
  );

  const {
    data: workflowHistories,
    count: workflowHistoriesCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<WorkflowHistory>({
    queryKey: ["workflow_histories"],
    sort,
    service: workflowHistoryService,
    options: { workflowId },
    queryParams: false,
  });

  const rowClicked = useCallback(
    (id: number) => {
      setWorkflowHistoryId(id);
    },
    [setWorkflowHistoryId],
  );

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={workflowHistories}
        tableColumns={columns}
        length={workflowHistoriesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        rowClicked={rowClicked}
        enableRowSelection
        emptyText={t("translation:workflow_runs_not_found")}
      />
    </div>
  );
}
