import React, { useCallback, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import { App, Integration as IntegrationType } from "/app/src/models";
import Controls from "./controls";
import Filters from "./filters";
import NewForm from "./newDisplayForm";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import DeleteButtonCell from "/app/src/components/generic/tables/deleteButtonCell";
import usePaginatedData from "/app/src/hooks/usePaginatedData";

/**
 * Returns a table with the the displays
 * @param param0 object containing app
 */
export default function DisplaysList({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const {
    data: displays,
    count: displaysCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
    refetch,
  } = usePaginatedData<IntegrationType>({
    queryKey: ["integrations"],
    searchString,
    sort,
    service: integrationService,
    options: { appId: app.id },
  });

  const invalidateDisplays = useCallback(() => {
    refetch();
  }, [refetch]);

  const deleteIntegration = useCallback((id: number) => {
    return integrationService.deleteSingle(id);
  }, []);

  const columnHelper = createColumnHelper<IntegrationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("token", {
        id: "token",
        cell: (info) => info.getValue(),
        header: t("translation:description"),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (info) => (
          <DeleteButtonCell
            id={info.getValue()}
            refresh={invalidateDisplays}
            deleteFn={deleteIntegration}
          />
        ),
        header: "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
    ],
    [columnHelper, deleteIntegration, invalidateDisplays, t],
  );

  const goToDisplay = useCallback(
    (integrationId: number) => {
      navigate(`/apps/${app.id}/displays/${integrationId}`);
    },
    [app.id, navigate],
  );

  const [activeNew, setActiveNew] = useState(false);
  const toggleNew = useCallback(() => {
    setActiveNew(!activeNew);
  }, [activeNew]);

  return (
    <div className="app" id="displays">
      <Helmet>
        <title>{t("translation:displays")} - ItemPath</title>
      </Helmet>
      <Controls app={app} toggleNew={toggleNew} activeNew={activeNew} />
      {activeNew && <NewForm app={app} />}
      <Row gutter={20}>
        <Col span={6}>
          <Filters setSearchString={setSearchString} />
        </Col>

        <Col span={18}>
          <Table
            loading={isFetching}
            rows={displays}
            tableColumns={columns}
            length={displaysCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPageSize: settingPageSize,
              setPage: settingPage,
            }}
            enableRowSelection
            rowClicked={goToDisplay}
            emptyText={t("translation:no_displays_found")}
          />
        </Col>
      </Row>
    </div>
  );
}
