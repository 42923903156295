import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { orderLineService } from "/app/src/services";

import Search from "../search";

import { useTranslation } from "react-i18next";
import { OrderLine as OrderLineType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
export default function OrderLines() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<OrderLineType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("number", {
        id: "number",
        cell: (info) => info.getValue(),
        header: () => t("translation:number"),
      }),
      columnHelper.accessor("Info1", {
        id: "Info1",
        cell: (info) => info.getValue(),
        header: () => t("translation:info1"),
      }),
    ],
    [columnHelper, t],
  );

  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToOrderLine = useCallback(
    (orderLineId: number) => {
      navigate(`/explorer/order_lines/${orderLineId}`);
    },
    [navigate],
  );

  const {
    data: orderLines,
    count: orderLinesCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<OrderLineType>({
    queryKey: ["order_lines"],
    searchString,
    sort,
    service: orderLineService,
    options: {
      state: "queued",
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:order_lines")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={orderLines}
        tableColumns={columns}
        length={orderLinesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToOrderLine}
        emptyText={t("translation:no_order_lines")}
      />
    </div>
  );
}
