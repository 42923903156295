import Criteria from "./criteria";
import NewForm from "./newForm";
import { workflowCriteriaService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { WorkflowCriteria, Workflow, Report } from "/app/src/models";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
export default function Criterias({
  workflow,
  reports,
  isFetchingReports,
}: {
  workflow: Workflow;
  reports: Report[];
  isFetchingReports: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Get API data
  const { data: workflowCriterias } = useQuery({
    queryKey: ["criteriaByWorkflow", workflow.id],
    queryFn: () => {
      return workflowCriteriaService.getByWorkflow(workflow.id);
    },
    enabled: Boolean(workflow.id),
    initialData: { workflow_criterias: [] },
    select: (data: { workflow_criterias: WorkflowCriteria[] }) => {
      return data.workflow_criterias;
    },
  });

  const { mutateAsync: addWorkflowCriteria } = useMutation({
    mutationFn: (workflowCriteria: WorkflowCriteria) => {
      return workflowCriteriaService
        .createSingle(workflowCriteria)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["criteriaByWorkflow", workflow.id],
        (oldData: { workflow_criterias: WorkflowCriteria[] }) => {
          return {
            workflow_criterias: [
              ...oldData.workflow_criterias,
              response.workflow_criteria,
            ],
          };
        },
      );
    },
  });

  const { mutateAsync: removeWorkflowCriteria } = useMutation({
    mutationFn: (workflowCriteria: WorkflowCriteria) => {
      return workflowCriteriaService
        .deleteSingle(workflowCriteria.id)
        .then(() => {
          const workflowCriteriaId = workflowCriteria.id;
          return { workflowCriteriaId };
        });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["criteriaByWorkflow", workflow.id],
        (oldData: { workflow_criterias: WorkflowCriteria[] }) => {
          return {
            workflow_criterias: oldData.workflow_criterias.filter(
              (criteria) => criteria.id !== response.workflowCriteriaId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateWorkflowCriteria } = useMutation({
    mutationFn: (updatedCriteria: WorkflowCriteria) => {
      return workflowCriteriaService
        .updateSingle(updatedCriteria.id, updatedCriteria)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["criteriaByWorkflow", workflow.id],
        (oldData: { workflow_criterias: WorkflowCriteria[] }) => {
          return {
            workflow_criterias: oldData.workflow_criterias.map((criteria) => {
              if (criteria.id === response.workflow_criteria.id) {
                return response.workflow_criteria;
              }
              return criteria;
            }),
          };
        },
      );
    },
  });

  return (
    <div>
      <div
        className="criteria"
        style={{ display: workflowCriterias.length > 0 ? "block" : "none" }}
      >
        <h2>{t("translation:criteria")}</h2>
        {workflowCriterias.map((criteria) => (
          <Criteria
            criteria={criteria}
            key={criteria.id}
            removeWorkflowCriteria={removeWorkflowCriteria}
            updateWorkflowCriteria={updateWorkflowCriteria}
            reports={reports}
            isFetchingReports={isFetchingReports}
          />
        ))}
      </div>
      <NewForm
        workflow={workflow}
        addWorkflowCriteria={addWorkflowCriteria}
        reports={reports}
      />
    </div>
  );
}
