import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { stationService, warehouseService } from "/app/src/services";
import Search from "../search";
import { useDebounce } from "/app/src/hooks";

import {
  Station as StationType,
  Warehouse as WarehouseType,
} from "/app/src/models";
import { Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
const { Option } = Select;

/**
 * Shows a table with all stations
 */
export default function StationsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [typeFilter, setTypeFilter] = useState("all");
  const [warehouseFilter, setWarehouseFilter] = useState("all");
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString, 1000);

  //This array is used to map the station type to its description.
  //These descriptions were provided by Bill: https://linear.app/itempath/issue/DEV-423#comment-578e62e1
  const stationTypes = [
    { value: 1, description: t("translation:pc") },
    { value: 5, description: t("translation:web_client") },
    { value: 6, description: t("translation:server") },
    { value: 7, description: t("translation:mobile_client") },
    { value: 8, description: t("translation:terminal_client") },
  ];

  const columnHelper = createColumnHelper<StationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("warehouseName", {
        id: "warehouseName",
        cell: (info) => info.getValue(),
        header: () => t("translation:warehouse"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "typeDescription",
        cell: (info) => info.getValue(),
        header: () => t("translation:type"),
      }),
    ],
    [columnHelper, t],
  );

  const goToStation = useCallback(
    (id: number) => {
      navigate(`/explorer/stations/${id}`);
    },
    [navigate],
  );

  const settingWarehouseFilter = useCallback((value) => {
    setWarehouseFilter(value);
  }, []);

  const settingTypeFilter = useCallback((value) => {
    setTypeFilter(value);
  }, []);

  const { data: warehouses } = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => {
      return warehouseService.getAll();
    },
    initialData: { warehouses: [] },
    select: (data: { warehouses: WarehouseType[] }) => {
      return data.warehouses;
    },
  });

  const {
    data: stations,
    count: stationsCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<StationType>({
    queryKey: ["stations"],
    searchString: debouncedSearchTerm,
    sort,
    service: stationService,
    options: {
      warehouseId: warehouseFilter,
      type: typeFilter,
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:stations")} - ItemPath</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={12}>
            <Search setSearchString={setSearchString} />
          </Col>
          <Col span={6}>
            <Select
              onChange={settingWarehouseFilter}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_warehouse")}
            >
              <Option value="all">{t("translation:all")}</Option>
              {warehouses.map((warehouse) => (
                <Option value={warehouse.id} key={warehouse.id}>
                  {warehouse.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              onChange={settingTypeFilter}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_station_type")}
            >
              <Option value="all">{t("translation:all")}</Option>

              {stationTypes.map((type) => (
                <Option value={type.value} key={type.value}>
                  {type.description}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Box>
      <Table
        loading={isFetching}
        rows={stations}
        tableColumns={columns}
        length={stationsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToStation}
        emptyText={t("translation:no_stations_found")}
      />
    </div>
  );
}
