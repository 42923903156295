import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Connection, Integration } from "/app/src/models";
import MaxRecords from "./maxRecords";
import Timeout from "./timeout";
import Verify from "./verify";
import Path from "./path";
import Retries from "./retries";
import ConnectionForm from "./connection";
import { isAPIType } from "/app/src/helpers";
import MethodSetting from "./methodSetting";
import Box from "/app/src/components/generic/components/box";

/**
 * Component to display the connection settings for a Data Push Integration
 * @param integration Integration
 */
export default function ConnectionSettings({
  integration,
  path = true,
  verify = true,
  timeout = true,
  maxRecords = true,
  retries = true,
  connections,
  method = false,
  isFetchingConnections,
}: {
  integration: Integration;
  path?: boolean;
  verify?: boolean;
  timeout?: boolean;
  maxRecords?: boolean;
  retries?: boolean;
  connections: Connection[];
  method?: boolean;
  isFetchingConnections?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <h1>{t("translation:connection")}</h1>
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <ConnectionForm
            connections={connections}
            isFetchingConnections={isFetchingConnections}
            integration={integration}
          />
        </Col>
        {integration.connectionType === "REST" && path && (
          <Col span={12}>
            <Path integration={integration} />
          </Col>
        )}
      </Row>
      {isAPIType(integration.connectionType) && (
        <Row justify="start" gutter={16}>
          {verify && (
            <Col span={4}>
              <Verify integration={integration} />
            </Col>
          )}
          {timeout && (
            <Col span={6}>
              <Timeout integration={integration} />
            </Col>
          )}
          {maxRecords && (
            <Col span={6}>
              <MaxRecords integration={integration} />
            </Col>
          )}
          {retries && (
            <Col span={6}>
              <Retries integration={integration} />
            </Col>
          )}
          {method && (
            <Col span={18}>
              <MethodSetting
                params={{ integrationId: integration.id, type: "method" }}
              />
            </Col>
          )}
        </Row>
      )}
    </Box>
  );
}
