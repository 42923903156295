import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { dynamicFieldService } from "/app/src/services";

import Search from "../search";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
import { Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import { DynamicField as DynamicFieldType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";

const { Option } = Select;

/**
 * Shows a table with all dynamic fields
 */
export default function DynamicFieldsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filter, setFilter] = useState("all");
  const [baseTables, setBaseTables] = useState<string[]>([]);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");
  const columnHelper = createColumnHelper<DynamicFieldType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("baseTable", {
        id: "baseTable",
        cell: (info) => info.getValue(),
        header: t("translation:base_table"),
      }),
    ],
    [t, columnHelper],
  );

  const goToFields = useCallback(
    (id: number) => {
      navigate(`/explorer/dynamic_fields/${id}`);
    },
    [navigate],
  );

  const settingFilterChange = useCallback(
    (value) => {
      setFilter(value);
    },
    [setFilter],
  );

  const {
    data: dynamicFields,
    count: dynamicFieldsCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<DynamicFieldType>({
    queryKey: ["dynamic_fields"],
    searchString,
    sort,
    service: dynamicFieldService,
    options: {
      baseTable: filter,
    },
  });

  useEffect(() => {
    if (filter === "all") {
      //get list of base tables from the dynamic fields
      const tables: string[] = [];
      dynamicFields.forEach((field: DynamicFieldType) => {
        //add baseTable to array if unique
        if (field?.baseTable && !tables.includes(field.baseTable)) {
          tables.push(field.baseTable);
        }
      });
      setBaseTables(tables);
    }
  }, [dynamicFields, filter]);

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:dynamic_fields")} - ItemPath</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={12}>
            <Search setSearchString={setSearchString} />
          </Col>
          <Col span={12}>
            <Select
              onChange={settingFilterChange}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_base_table")}
            >
              <Option value="all">{"All"}</Option>

              {baseTables.map((table) => (
                <Option value={table} key={table}>
                  {table}
                </Option>
              ))}
            </Select>
            {}
          </Col>
        </Row>
      </Box>

      <Table
        loading={isFetching}
        rows={dynamicFields}
        tableColumns={columns}
        length={dynamicFieldsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToFields}
        emptyText={t("translation:no_dynamic_fields_found")}
      />
    </div>
  );
}
