import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { storageRuleService } from "/app/src/services";

import Search from "../search";

import { useTranslation } from "react-i18next";
import { StorageRule as StorageRuleType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
export default function StorageRules() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<StorageRuleType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => t("translation:description"),
      }),
      columnHelper.accessor("requiredCapacity", {
        id: "requiredCapacity",
        cell: (info) => info.getValue(),
        header: () => t("translation:capacity"),
      }),
    ],
    [columnHelper, t],
  );

  const goToStorageRule = useCallback(
    (storageRuleId: number) => {
      navigate(`/explorer/storage_rules/${storageRuleId}`);
    },
    [navigate],
  );

  const {
    data: storageRules,
    count: storageRulesCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<StorageRuleType>({
    queryKey: ["storage_rules"],
    searchString,
    sort,
    service: storageRuleService,
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:storage_rules")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={storageRules}
        tableColumns={columns}
        length={storageRulesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage: settingPage,
          setPageSize: settingPageSize,
        }}
        enableRowSelection
        rowClicked={goToStorageRule}
        emptyText={t("translation:no_storage_rules")}
      />
    </div>
  );
}
