import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { shelfService } from "/app/src/services";
import Search from "../search";
import { useTranslation } from "react-i18next";
import { Shelf as ShelfType } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
export default function Shelves() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<ShelfType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("number", {
        id: "number",
        cell: (info) => info.getValue(),
        header: () => t("translation:number"),
      }),
      columnHelper.accessor("sequenceNumber", {
        id: "sequenceNumber",
        cell: (info) => info.getValue(),
        header: () => t("translation:sequence_number"),
      }),
    ],
    [columnHelper, t],
  );

  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const goToShelf = useCallback(
    (shelfId: number) => {
      navigate(`/explorer/shelves/${shelfId}`);
    },
    [navigate],
  );

  const {
    data: shelves,
    count: shelvesCount,
    isFetching,
    page,
    pageSize,
    settingPage,
    settingPageSize,
  } = usePaginatedData<ShelfType>({
    queryKey: ["shelves"],
    searchString,
    sort,
    service: shelfService,
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:shelves")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={shelves}
        tableColumns={columns}
        length={shelvesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPageSize: settingPageSize,
          setPage: settingPage,
        }}
        enableRowSelection
        rowClicked={goToShelf}
        emptyText={t("translation:no_shelves_found")}
      />
    </div>
  );
}
