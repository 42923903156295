import { Row, Col } from "antd";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import PowerPickChart from "./powerPickChart";
import { useTranslation } from "react-i18next";
import { useConnectionStatusContext } from "/app/src/contexts/hooks/useConnectionStatusContext";
import StatusHook from "/app/src/components/status/statusHook";
import ConnectionsList from "/app/src/components/status/connections/connectionsList";
import Box from "/app/src/components/generic/components/box";

/**
 * Component for displaying connection status
 * @returns JSX.Element
 *
 */
export default function ConnectionStatus() {
  const { t } = useTranslation();
  const { webServices, powerPick } = useConnectionStatusContext();
  const { powerPickMSSQL, powerPickWebServices } = StatusHook();

  return (
    <Box>
      <Row gutter={15} className="flex justify-between">
        <Col span={8}>
          <h3 className="text-[#727f92] font-bold flex items-center">
            {t("translation:powerpick_database")}{" "}
            <IconToolTip
              content={t("translation:powerpick_chart")}
              className="ml-1"
              color="#727f92"
              size={18}
            />
          </h3>
        </Col>
        <Col span={1}>
          <div className="flex items-center justify-center">
            {powerPick ? (
              <IconToolTip
                content={t("translation:connected_powerpick_database")}
                icon="CircleCheck"
                size={20}
                color="#52c41a"
              />
            ) : (
              <IconToolTip
                content={t("translation:disconnect_powerpick_connection")}
                icon="Warning"
                size={20}
                color="#d0021b"
              />
            )}
          </div>
        </Col>
      </Row>
      <HeroDivider className="my-6" />
      <PowerPickChart data={powerPickMSSQL} />
      <div>&nbsp;</div>
      <Row gutter={15} className="flex justify-between pt-[20px]">
        <Col span={8}>
          <h3 className="text-[#727f92] font-bold flex items-center">
            {t("translation:powerpick_web_services")}
            <IconToolTip
              content={t("translation:powerpick_chart")}
              className="ml-1"
              color="#727f92"
              size={18}
            />
          </h3>
        </Col>
        <Col span={1}>
          <div className="flex items-center justify-center">
            {webServices ? (
              <IconToolTip
                content={t("translation:connected_powerpick_web_services")}
                icon="CircleCheck"
                size={20}
                color="#52c41a"
              />
            ) : (
              <IconToolTip
                content={t("translation:disconnect_powerpick_web_services")}
                icon="Warning"
                size={20}
                color="#d0021b"
              />
            )}
          </div>
        </Col>
      </Row>
      <HeroDivider className="my-6" />
      <PowerPickChart data={powerPickWebServices} />
      <div>&nbsp;</div>
      <Row>
        <Col span={24}>
          <h3 className="chart-title" style={{ paddingTop: 20 }}>
            {t("translation:other_connections")}
          </h3>
        </Col>
      </Row>
      <HeroDivider className="my-6" />
      <ConnectionsList />
    </Box>
  );
}
